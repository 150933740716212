import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import HealthcareModal from './HealthcareModal';

export default class FullDevelopmentCommercial extends PureComponent {
  render() {
    const { column } = this.props;
    return (
      <Col xs={12} lg={4} className="text-center my-3">
        <Row className="justify-content-center">
          <Col xs={12} className={`text-white mb-md-3 dev-menu ${column.class}`}>
            <div className="mb-3">
              <img src={column.image.publicURL} alt="" style={{ height: '50px', width: '50px' }} />
            </div>
            <h6>{column.title}</h6>
          </Col>
          {column.list.map(button => (
            <HealthcareModal button={button} />
          ))}
        </Row>
      </Col>
    );
  }
}
