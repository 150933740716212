import React, { PureComponent } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
// import { Container, Row, Col, Tab, Nav, Button, Modal } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import { Link, graphql } from 'gatsby';
import Observer from 'react-intersection-observer';
import Layout from '../components/Layout';
import SEO from '../components/seo';

import '../sass/_healthcare-consulting.scss';
import Triagle from '../../static/img/healthcare_consulting/Healthcare_Triagle.svg';
import * as globalAnimation from '../animations/globalAnimation';
import ReadMore from '../../static/assets/Readmore.svg';
import {
  animateStrategyCards,
  animateButton,
  animateDevMenu,
} from '../animations/HealthcareConsulting';
import FullDevelopmentCommercial from '../components/FullDevelopmentCommercial';
import BusinessModal from '../components/Business Development/BusinessModal';
import EarlyModal from '../components/Early Development/EarlyModal';
import EnterpriseModal from '../components/Enterprise Strategy/EnterpriseModal';

export default class ourMission extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.AnimateEaseInOut(this.tl, '.triangle-animation');
  }

  animateTabs = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateStrategyCards(tl, '.animate-card');
    }
  };

  animateFullDevelopment = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateDevMenu(tl, '.dev-menu');

      animateButton(tl, '.animate-btn');
    }
  };

  render() {
    const {
      // data,
      data: {
        markdownRemark: {
          frontmatter: {
            // page,
            // image,
            links,
            tabOne,
            tabThree,
            tabThree: { subTabOne, subTabTwo, subTabThree },
          },
        },
      },
    } = this.props;

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Healthcare Consulting" />
        <Container fluid className="px-0">
          <Row className="no-gutters justify-content-center">
            <Col xs={12}>
              <div className="main_wrapper">
                <div className="wrapper">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">
                      HealthCare Consulting
                    </h3>
                    <h4 className="text-uppercase page_heading_child subtitle">
                      HealthCare Consulting
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={5} className="mb-md-5">
              <div style={{ width: 'auto' }} className="mx-4">
                {/* <div style={{ height: 'calc(100vh - 192px)', width: 'auto' }} className="mx-4"> */}
                <img src={Triagle} alt="" className="w-100 h-100 triangle-animation ml-lg-5" />
              </div>
            </Col>
          </Row>

          <Row noGutters>
            <Col xs={12}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="third">
                <svg className="hidden">
                  <defs>
                    <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z" />
                  </defs>
                </svg>
                <Row className="tabs-style-shape no-gutters tabs_design">
                  <Col sm={12} className="nav_dtab">
                    <Nav justify variant="pills" className="ul_dtab">
                      {links.map(link => (
                        <Nav.Item className="li_dtab">
                          <Nav.Link eventKey={link.eventKey} className="a_tab pb-0">
                            <div className={link.class}>
                              <div className="d-none d-md-block">
                                <svg viewBox="0 0 80 59" preserveAspectRatio="none">
                                  <use xmlns="http://www.w3.org/1999/xlink" xlinkHref="#tabshape" />
                                </svg>
                                <svg viewBox="0 0 80 59" preserveAspectRatio="none">
                                  <use xmlns="http://www.w3.org/1999/xlink" xlinkHref="#tabshape" />
                                </svg>
                              </div>
                              <span className="font-weight-bold">{link.title}</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane
                        eventKey={tabOne.eventKey}
                        transition={false}
                        className={tabOne.class}
                      >
                        <div className="health_tab py-5">
                          <Container>
                            <Observer onChange={this.animateTabs} triggerOnce>
                              <Row className="justify-content-center">
                                {tabOne.cards.map(tabdata => (
                                  <EnterpriseModal tabOne={tabdata} img={tabOne.image} />
                                ))}
                              </Row>
                            </Observer>
                          </Container>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey={tabThree.eventKey}
                        transition={false}
                        className={tabThree.class}
                      >
                        <div className="health_tab pt-lg-5">
                          <Tab.Container id="left-tabs-example" defaultActiveKey="third">
                            <svg className="hidden">
                              <defs>
                                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z" />
                              </defs>
                            </svg>
                            <Row className="tabs-style-shape no-gutters tabs_design">
                              <Col sm={12} className="nav_dtab">
                                <Nav justify variant="pills" className="ul_dtab">
                                  {tabThree.subTabThree.subLinks.map(link => (
                                    <Nav.Item className="li_dtab">
                                      <Nav.Link eventKey={link.eventKey} className="a_tab pb-0">
                                        <div className={link.class}>
                                          <div className="d-none d-md-block">
                                            <svg viewBox="0 0 80 59" preserveAspectRatio="none">
                                              <use
                                                xmlns="http://www.w3.org/1999/xlink"
                                                xlinkHref="#tabshape"
                                              />
                                            </svg>
                                            <svg viewBox="0 0 80 59" preserveAspectRatio="none">
                                              <use
                                                xmlns="http://www.w3.org/1999/xlink"
                                                xlinkHref="#tabshape"
                                              />
                                            </svg>
                                          </div>
                                          <span className="font-weight-bold">
                                            {link.title1}
                                            <br />
                                            {link.title2}
                                          </span>
                                        </div>
                                      </Nav.Link>
                                    </Nav.Item>
                                  ))}
                                </Nav>
                              </Col>
                              <Col sm={12}>
                                <Tab.Content>
                                  <Tab.Pane
                                    eventKey={subTabOne.eventKey}
                                    transition={false}
                                    className={subTabOne.class}
                                  >
                                    <div className="health_tab py-5">
                                      <Container>
                                        <Observer onChange={this.animateTabs} triggerOnce>
                                          <Row className="justify-content-center">
                                            {subTabOne.cards.map(tabdata => (
                                              <EarlyModal
                                                subTabOne={tabdata}
                                                img={subTabOne.image}
                                              />
                                            ))}
                                          </Row>
                                        </Observer>
                                      </Container>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane
                                    eventKey={subTabTwo.eventKey}
                                    transition={false}
                                    className={subTabTwo.class}
                                  >
                                    <div className="health_tab py-5">
                                      <Container>
                                        <Observer onChange={this.animateTabs} triggerOnce>
                                          <Row className="justify-content-center">
                                            {subTabTwo.cards.map(tabdata => (
                                              <BusinessModal subTabTwo={tabdata} />
                                            ))}
                                          </Row>
                                        </Observer>
                                      </Container>
                                    </div>
                                  </Tab.Pane>
                                  <Tab.Pane
                                    eventKey={subTabThree.eventKey}
                                    transition={false}
                                    className={subTabThree.class}
                                  >
                                    <div className="health_tab py-lg-5">
                                      <Container>
                                        <Observer
                                          onChange={this.animateFullDevelopment}
                                          triggerOnce
                                        >
                                          <Row className="justify-content-center">
                                            {tabThree.subTabThree.columns.map(column => (
                                              <FullDevelopmentCommercial column={column} />
                                            ))}
                                          </Row>
                                        </Observer>
                                      </Container>
                                    </div>
                                  </Tab.Pane>
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
            <Col xs={12}>
              <div className="align-items-center d-flex flex-column flex-md-row justify-content-center text-center my-5 px-3">
                <h5 className="text-bold text-appcolor my-3 my-md-0">
                  Explore examples of typical BluePrint engagements
                </h5>
                <Link
                  to="/typical-engagements/"
                  className="bordered-btn mx-md-3 py-1 learn_more text-center"
                >
                  Discover more
                  <ReadMore className="arrow-translate" height="10px" width="40px" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "healthcare-consulting" } }) {
      frontmatter {
        page
        image {
          publicURL
        }
        links {
          eventKey
          class
          title
        }
        tabOne {
          title
          eventKey
          image {
            publicURL
          }
          class
          cards {
            title
            modal {
              header
              sectionOne {
                title
                description
                image {
                  publicURL
                }
              }
              sectionTwo {
                title
                lists {
                  id
                  text
                }
                image {
                  publicURL
                }
              }
            }
          }
        }

        tabThree {
          title
          eventKey
          class
          subTabOne {
            title
            eventKey
            class
            image {
              publicURL
            }
            cards {
              title
              modal {
                header
                sectionOne {
                  title
                  description
                  image {
                    publicURL
                  }
                }
                sectionTwo {
                  title
                  lists {
                    id
                    text
                  }
                  image {
                    publicURL
                  }
                }
              }
            }
          }
          subTabTwo {
            title
            eventKey
            class
            cards {
              title
              image {
                publicURL
              }
              modal {
                header
                sectionOne {
                  title
                  description
                  image {
                    publicURL
                  }
                }
                sectionTwo {
                  title
                  lists {
                    id
                    text
                  }
                  image {
                    publicURL
                  }
                }
              }
            }
          }
          subTabThree {
            title
            eventKey
            class
            subLinks {
              eventKey
              class
              title1
              title2
            }
            columns {
              title
              image {
                publicURL
              }
              class
              list {
                title
                modal {
                  header
                  sectionOne {
                    title
                    description
                    image {
                      publicURL
                    }
                  }
                  sectionTwo {
                    title
                    lists {
                      id
                      text
                    }
                    image {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
