import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import Arrow from '../../../static/img/healthcare_consulting/Readmore-white.svg';
import Arrowb from '../../../static/assets/healthcare_consulting/Readmore.svg';

export default class HealthcareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleShowModal = () => {
    const { button } = this.props;
    if (button.modal !== null) {
      document.getElementsByTagName('nav')[0].classList.remove('fixed-top');
      document.getElementsByClassName('main_wrapper')[0].style.marginTop = 0;
    }
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    document.getElementsByTagName('nav')[0].classList.add('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = '64px';
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { showModal } = this.state;
    const { button } = this.props;
    return (
      <Col xs={12} className="text-center my-3 animate-btn">
        {button.modal !== null ? (
          <Button
            onClick={this.handleShowModal}
            className="list_tab_hover w-100 h-100 border-0 py-4 d-flex justify-content-center"
            style={{ borderRadius: '0' }}
          >
            <p className="text-semibold">{button.title}</p>

            <div className="hover_arrow">
              <img src={Arrowb} className="before_hover mt-1" width="20px" alt="" />
              <img src={Arrow} className="after_hover mt-1" width="20px" alt="" />
            </div>
          </Button>
        ) : (
          <Button
            className="list_tab w-100 h-100 border-0 py-4 d-flex justify-content-center"
            style={{ borderRadius: '0' }}
          >
            <p className="text-semibold">{button.title}</p>
          </Button>
        )}
        {button.modal !== null ? (
          <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton className="border-0 pb-0" />
            <Modal.Body className="mx-lg-4 mx-2 py-0 px-lg-5">
              <Row className="no-gutters">
                <Col xs={12}>
                  <h4 className="text-appcolor text-center text-lg-left">{button.modal.header}</h4>
                </Col>
              </Row>
              <Row className="my-4 no-gutters">
                <Col xs={12} lg={2}>
                  <div className="text-appcolor text-center text-lg-left">
                    <img
                      src={button.modal.sectionOne.image.publicURL}
                      alt=""
                      style={{
                        height: '80px',
                        width: '80px',
                      }}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={10}>
                  <h5 className="text-appcolor text-appcolor text-center text-lg-left mb-3">
                    {button.modal.sectionOne.title}
                  </h5>
                  <p>{button.modal.sectionOne.description}</p>
                </Col>
              </Row>
              <Row className="my-4 no-gutters">
                <Col xs={12} lg={2}>
                  <div className="text-appcolor text-center text-lg-left">
                    <img
                      src={button.modal.sectionTwo.image.publicURL}
                      alt=""
                      style={{
                        height: '80px',
                        width: '80px',
                      }}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={10} className="mb-4">
                  <h5 className="text-appcolor text-center text-lg-left mb-3">
                    {button.modal.sectionTwo.title}
                  </h5>
                  <div className="circle-light-bullets">
                    <ul style={{ paddingLeft: '23px' }}>
                      {button.modal.sectionTwo.lists.map(list => (
                        <li key={list.id} className="pl-2">
                          {list.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        ) : null}
      </Col>
    );
  }
}
