import React, { Component } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';

export default class EarlyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleShowModal = () => {
    const { subTabOne } = this.props;
    if (subTabOne !== null) {
      document.getElementsByTagName('nav')[0].classList.remove('fixed-top');
      document.getElementsByClassName('main_wrapper')[0].style.marginTop = 0;
    }
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    document.getElementsByTagName('nav')[0].classList.add('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = '64px';
    this.setState({
      showModal: false,
    });
  };

  render() {
    const { showModal } = this.state;
    const { subTabOne, img } = this.props;
    return (
      // <div>

      <Col xs={12} md={4} className="h-auto text-center my-3">
        {subTabOne.modal !== null ? (
          <div
            className="list_tab_hover h-100 w-100 p-lg-4 p-3 animate-card rounded-0"
            onClick={this.handleShowModal}
          >
            <div className="my-3">
              <img src={img.publicURL} alt="" style={{ height: '50px', width: '50px' }} />
            </div>

            <p className="font-weight-bold">{subTabOne.title}</p>
          </div>
        ) : (
          <div className="list_tab h-100 p-lg-4 p-3 animate-card" onClick={this.handleShowModal}>
            <div className="my-3">
              <img src={img.publicURL} alt="" style={{ height: '50px', width: '50px' }} />
            </div>

            <p className="font-weight-bold">{subTabOne.title}</p>
          </div>
        )}
        {subTabOne.modal !== null ? (
          <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton className="border-0 pb-0" />
            <Modal.Body className="mx-lg-4 mx-2 py-0 px-lg-5">
              <Row className="no-gutters">
                <Col xs={12}>
                  <h4 className="text-appcolor text-center text-lg-left">
                    {subTabOne.modal.header}
                  </h4>
                </Col>
              </Row>
              <Row className="my-4 no-gutters">
                <Col xs={12} lg={2}>
                  <div className="text-appcolor text-center text-lg-left">
                    <img
                      src={subTabOne.modal.sectionOne.image.publicURL}
                      alt=""
                      style={{
                        height: '80px',
                        width: '80px',
                      }}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={10}>
                  <h5 className="text-appcolor text-appcolor text-center text-lg-left mb-3">
                    {subTabOne.modal.sectionOne.title}
                  </h5>
                  <p>{subTabOne.modal.sectionOne.description}</p>
                </Col>
              </Row>
              <Row className="my-4 no-gutters">
                <Col xs={12} lg={2}>
                  <div className="text-appcolor text-center text-lg-left">
                    <img
                      src={subTabOne.modal.sectionTwo.image.publicURL}
                      alt=""
                      style={{
                        height: '80px',
                        width: '80px',
                      }}
                    />
                  </div>
                </Col>
                <Col xs={12} lg={10} className="mb-4">
                  <h5 className="text-appcolor text-center text-lg-left mb-3">
                    {subTabOne.modal.sectionTwo.title}
                  </h5>
                  <div className="circle-light-bullets">
                    <ul style={{ paddingLeft: '23px' }}>
                      {subTabOne.modal.sectionTwo.lists.map(list => (
                        <li key={list.id} className="pl-2">
                          {list.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        ) : null}
      </Col>
    );
  }
}
